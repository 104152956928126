import React from 'react';
import './RequestTutor.scss';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import dot from '../../../Assets/Icons/dot.png';
import phone_icon from '../../../Assets/Icons/phone.png';
import frog_mobile from '../../../Assets/Home/frog_mobile.png';
import roland_photo from '../../../Assets/Home/roland_photo.png';
import { InputBox, } from '../../';
import { requestTutor } from '../../../Redux/Actions';
import Helper from '../../../Utils/Helper';


class RequestTutor extends React.Component {
    state = {
        name: '',
        email: '',
        phone: '',
        subject: '',
        zipcode: '',
        validation: false,
        emailValidation: undefined
    }

    componentDidUpdate(prevProps) {
        if (prevProps.request_loading && !this.props.request_loading) {
            Helper.hideSpinner();
            if(this.props.request_success){
                // Helper.showToast('success', 3000, "Thank you for your request!")
                this.props.history.push('/request-tutor/schedule');
            } else {
                Helper.showToast('error', 3000, this.props.request_message)
            }
        }
    }

    onChange = (field, val) => {
        this.setState({[field]: val});
	};

    submit(){
        const { name, email, phone, subject, zipcode} = this.state;
        this.setState({validation: true})
        let emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (emailReg.test(email) === false) {
            this.setState({emailValidation: false});
        } else {
            this.setState({emailValidation: true});
        };
        if(name && emailReg.test(email) && phone && subject && zipcode){
            Helper.showSpinner();

            const deploy = {
                name,
                email,
                phone,
                subject,
                zipcode,
                info: "I need tutoring immediately",
                region: this.props.city,
                level_id: 2,
                client_id: ""
            }
		    const formData = new FormData();		
            formData.append('name', name);
            formData.append('email', email);
            formData.append('phone', phone);
            formData.append('subject', subject);
            formData.append('zipcode', zipcode);
            formData.append('info', "I need tutoring immediately");
            formData.append('region', "Fort Worth, TX");
            formData.append('level_id', 2);
            formData.append('client_id', "This is Google Adword Client ID");
            formData.append('origin', "Request from landing2021");

            this.props.requestTutor(formData);
        }
    }

    render() {
        const { show_list, name, email, phone, subject, zipcode, validation, emailValidation } = this.state;
        const { phoneNumber, city } = this.props;
        const phoneNumber1 = phoneNumber ? phoneNumber : '817 840 7732';
        return (
            <div className="requestTutor-component">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 v-r">
                            <h1>Get more information from our <strong className="title-strong"><br/>Student Success Advocate</strong></h1>
                            <div className="show-web">
                                <div className="mt-4">
                                    <img src={dot} alt="dot" className="dot" />
                                    <p>We match you with a high-performing 1-on-1 tutor with a track record of success backed by our 100% money-back guarantee."</p>
                                </div>
                                <div className="ceo-container v-c">
                                    <img src={roland_photo} alt="avatar" className="avatar mr-3" />
                                    <div>
                                        <div className="name">Roland Omene</div>
                                        <div className="role">CEO at Frog Tutoring</div>
                                    </div>
                                </div>
                                <div className="line"></div>
                                <div className="or v-c h-c">or</div>
                                <div className="v-r v-c mt-5">
                                    <h2>Call us now at</h2>
                                    <a href={`tel:+1${phoneNumber1}`} className="v-c mt-4 phone-container">
                                        <img src={phone_icon} alt="phone" className="phone mr-3" />
                                        <div className="phone">{phoneNumber1}</div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="submit-form">
                                <h2 className="ruequest-header">Request tutoring info</h2>
                                <div className="input-container">
                                    <InputBox className={`input-box ${validation && !name? "inputbox-container-error": ""}`} requestInfo={true} placeholder="Full name" value={this.state.name} onChange={e => this.onChange('name', e)} />
                                    <InputBox className={`input-box ${validation && !emailValidation? "inputbox-container-error": ""}`} requestInfo={true} placeholder="Email" value={this.state.email} onChange={e => this.onChange('email', e)} />
                                    <InputBox className={`input-box ${validation && !phone.length? "inputbox-container-error": ""}`} requestInfo={true} type="number" placeholder="Phone" value={this.state.phone} onChange={e => this.onChange('phone', e)} />
                                    <InputBox className={`input-box ${validation && !subject? "inputbox-container-error": ""}`} requestInfo={true} placeholder="Tutoring needs" value={this.state.subject} onChange={e => this.onChange('subject', e)} />
                                    <InputBox className={`input-box ${validation && !zipcode? "inputbox-container-error": ""}`} requestInfo={true} type="number" placeholder="Zipcode" value={this.state.zipcode} onChange={e => this.onChange('zipcode', e)} />
                                    <div className="submit-btn v-c h-c btn" onClick={()=>this.submit()}>SUBMIT</div>
                                </div>
                            </div>
                            <div className="show-mobile">
                                <div className="mt-4">
                                    <img src={dot} alt="dot" className="dot" />
                                    <p>We match you with a high-performing 1-on-1 tutor with a track record of success backed by our 100% money-back guarantee."</p>
                                </div>
                                <div className="ceo-container v-c">
                                    <img src={roland_photo} alt="avatar" className="avatar mr-3" />
                                    <div>
                                        <div className="name">Roland Omene</div>
                                        <div className="role">CEO at Frog Tutoring</div>
                                    </div>
                                </div>
                                <div className="line"></div>
                                <div className="or v-c h-c">or</div>
                                <div className="v-r v-c mt-5">
                                    <h2>Call us now at</h2>
                                    <a href={`tel:+1${phoneNumber1}`} className="v-c mt-4 phone-container">
                                        <img src={phone_icon} alt="phone" className="phone mr-3" />
                                        <div className="phone">{phoneNumber1}</div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
};

function mapStateToProps(state) {
    return {
        request_loading: state.Request.request_loading,
        request_success: state.Request.request_success,
        request_message: state.Request.request_message,
    }
}

export default connect(mapStateToProps, { 
    requestTutor
 })(withRouter(RequestTutor));